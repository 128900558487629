/** ========================================================= * NextJS Material Dashboard 2 PRO - v2.0.0  ========================================================= * Product Page: https://www.creative-tim.com/product/nextjs-material-dashboard-pro  * Copyright 2022 Creative Tim (https://www.creative-tim.com)  Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// react
import { useMaterialUIController } from "/context";

// next
import dynamic from "next/dynamic";

// MUI
const Card = dynamic(() => import("@mui/material/Card"), { loading: () => null });

function BodyCard({ children, mt }) {
  const [controller] = useMaterialUIController();
  return (
    <Card
      sx={{
        p: controller.mobileView ? 1 : 2,
        mx: { xs: 1, lg: 3 },
        mt: mt !== undefined ? mt : -8,
        mb: 4,
        backgroundColor: ({ palette: { dark, white }, functions: { rgba } }) => rgba(controller.darkMode ? dark.main : white.main, 0.8),
        backdropFilter: "saturate(200%) blur(30px)",
        boxShadow: ({ boxShadows: { xxl } }) => xxl,
        overflow: "hidden",
      }}
    >
      {children}
    </Card>
  );
}

export default BodyCard;
